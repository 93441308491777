import * as React from "react";
import {Container, Grid} from "semantic-ui-react";
import HeaderData from "../components/layout/header"
import FooterData from "../components/layout/footer"
import Recientes from '../components/sidebar/Recientes/Recientes'

const IndexPage = ({ pageContext }) => {
  return (
    <div>
      <Container fluid>
            <Grid>
                <Grid.Column computer={1} className="mobile hidden tablet hidden">
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={14}>
                    <HeaderData/>
                </Grid.Column>
                <Grid.Column computer={1} className="mobile hidden tablet hidden">
                </Grid.Column>
            </Grid>
            <Grid >
                <Grid.Column computer={1} className="mobile hidden tablet hidden">
                </Grid.Column>
                <Grid.Column style={{padding: '0px'}} mobile={16} tablet={16} computer={10} fluid>
                  <picture className={pageContext.image === null ? 'hide' : 'no-hide'} >
                    <source srcset={pageContext.srcSetWebp} type="image/webp" />
                    <source srcset={pageContext.srcSet} type="image/jpeg" /> 
                    <img src={pageContext.srcWebp} alt={pageContext.title} style={{maxWidth: '100%',marginTop: '2em' ,padding: '1rem' ,border: 'solid 1px #000',}} />
                  </picture>
                  <h2>{pageContext.title}</h2>
                  <div style={{minHeight: '5em' ,color: '#000',fontSize:1 + 'em', fontWeight:'100'}} dangerouslySetInnerHTML={{ __html: pageContext.content.replace(
							/data:image\/gif;base64,R0lGODlhAQABAIAAAAAAAP\/\/\/yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" data-src="/gm,
							''
						)}} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={4}>
                <Recientes />
                </Grid.Column>
                <Grid.Column computer={1} className="mobile hidden tablet hidden">
                </Grid.Column>
            </Grid>

            
            <FooterData />
        </Container>
    </div>
  )
}
export default IndexPage